<template>
<div id="page-ajuste-cremeria">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Ajustes de Cremería" class="elevation-1 px-5 py-3">

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>
                    <template slot="articulos" slot-scope="props">
                        <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                            Ver {{ props.row.articulos.length }} artículos
                        </v-chip>
                    </template>
                    <template slot="actions" slot-scope="props">
                      <v-btn x-small fab dark color="green" @click.native="generaExcel(props.row)" title="Excel" v-tippy>
                        <v-icon>mdi-file-excel</v-icon>
                      </v-btn>
                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
    <v-dialog v-model="modalArticulos" max-width="95%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Artículos para Ajuste</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col style="text-align:right !important;">
                                    <h4>RETAZO: {{ total_retazo }} &nbsp;&nbsp;&nbsp;
                                        MERMA: {{ total_merma }} &nbsp;&nbsp;&nbsp;
                                        SOBRANTE: {{ total_sobrante }} &nbsp;&nbsp;&nbsp;
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos"
                                :columns="columnsArticulosVista" :options="optionsArticulosVista" class="elevation-2 pa-2">
                                <template slot="codigo_articulo" slot-scope="props">
                                    {{ props.row.codigo_articulo.join(", ") }}
                                </template>
                                <template slot="existencia" slot-scope="props">
                                    <div style="text-align:right">{{ parseFloat(props.row.existencia).toFixed(3) }}</div>
                                </template>
                                <template slot="ajuste" slot-scope="props">
                                    <div style="text-align:right">{{ parseFloat(props.row.ajuste).toFixed(3) }}</div>
                                </template>
                                <template slot="actions" slot-scope="props">
                                    <v-btn x-small fab dark color="pink"
                                        v-show="model_vista.estatus == 'Pendiente'"
                                        @click.native="deleteArticulo(props.row.id_articulo, props.row.descripcion)"
                                        title="Descartar Artículo" v-tippy>
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalArticulos=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                <v-btn color="success" @click.native="guardar()" v-show="(articulo_retazo!='' && model_vista.estatus == 'Pendiente')">
                    <v-icon>done</v-icon> Aplicar Ajustes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.inventarios.cremeria')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
      //TODO: traer el articulo RETAZO: 000000001564
      //si no existe no mostrar boton de Aplicar Ajustes y mostrar advertencia
      this.getArticuloRetazo();
  },
  data() {
    return {
        isLoading:false,
        sb_sucursal:"",
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
      tipos_ajuste: [
        {"nombre":"Aumentar"},
        {"nombre":"Disminuir"},
        ],
        modalAjuste:false,
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "fecha","nombre_sucursal","nombre_usuario","articulos","estatus","nombre_usuario_aplico","actions"
        ],
        options: {
            filterable: ["fecha","nombre_sucursal","nombre_usuario","estatus","nombre_usuario_aplico"],
            headings: {
                "nombre_sucursal":"Sucursal",
                "nombre_usuario":"Empleado",
                "nombre_usuario_aplico":"Usuario Aplicó",
                "actions":"Excel"
            },
            listColumns:{
              estatus: [{
                          id: 'Aplicado',
                          text: "Aplicado"
                      },
                      {
                          id: 'Pendiente',
                          text: "Pendiente"
                      }
                  ]
              },

        },
        columnsArticulosVista: ["codigo_barras","nombre","existencia","ajuste","descripcion","actions"],
        optionsArticulosVista: {
            filterable: ["codigo_barras","nombre","existencia","ajuste","descripcion"],
            headings: {
                "codigo_barras":"Codigo Barras",
                "actions":"Descartar"
            }
        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Inventario",
                href: "",
                disabled: true,
            },
            {
                text: "Ajustes de Cremería",
                href: ""
            }
        ],

        update: false,

        modelAjuste:{
            type:"lotes_ajustes",
            id_sucursal:"",
            nombre_sucursal:"",
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            cantidad:"",
            precio_compra:"",
            tipo:"Aumentar",
            actual:"",
            comentarios:"",
            usuario:"",
            fecha:""
        },
        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        articulos:[],
        articulos_vista: [],
        model_vista: "",
        modalArticulos: false,
        total_retazo:0,
        total_merma:0,
        total_sobrante:0,
        articulo_retazo:"",
    };
  },
  methods: {
    onStatusChanged(event) {
      this.registros.items = []; 
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {"type":"ajustes_cremeria","fecha":{"$exists":true}};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"type": "desc"},{"fecha": "desc"}],
            use_index:"_design/fb664f4382e7b2a3161c16c1022995a620a9dfc8"
          },
        }).then(async response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getArticuloRetazo: function(){
      window.dialogLoader.show('Espere un momento por favor..');
      var self = this;
      var busca = "000000001564"; //Articulo retazo
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/busca_codigo?key=\"' + busca + '\"')
        .then(response => {
          if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
            self.articulo_retazo = response.data.rows[0].value;
            window.dialogLoader.hide();
          } else { //Si no lo encuentra buscamos solo el numero
            busca = "1564"; //Articulo retazo
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/busca_codigo?key=\"' + busca + '\"')
            .then(response => {
              if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                  self.articulo_retazo = response.data.rows[0].value;
              } else {
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "No se encontró el articulo de RETAZO: 000000001564 en catálogo. Debe darse de alta para poder aplicar los ajustes.",
                    footer: ""
                });
              }
            })
            .catch(err => {
              self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al buscar el articulo de Retazo en catalogo.",
                  footer: error
              });
            }).then(()=>{
              window.dialogLoader.hide();
            });
          }

        })
        .catch(err => {
          self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al buscar el articulo de Retazo en catalogo.",
              footer: error
          });
          window.dialogLoader.hide();
        });
    },
    deleteArticulo: function (idArticulo, descripcion) {

        var descartado = this.model_vista.articulos.filter(e => {
            return e.id_articulo == idArticulo && e.descripcion == descripcion;
        });
        if (descartado.length > 0) {
            window.dialogLoader.show('Espere un momento por favor..');

            //Eliminar articulo del modelo
            var filter = this.model_vista.articulos.filter(e => {
                return e.id_articulo != idArticulo || e.descripcion != descripcion;
            });
            this.model_vista.articulos = filter;

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                .then(response => {
                    this.model_vista._rev = response.data.rev;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                        footer: error
                    });
                }).then(() => {
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });

        }
    },
    verArticulos: async function (row) {
        if(row.estatus == "Pendiente"){ //Solo si esta pendiente se obtiene sus existencias
          let procesado = await this.getExistencia(row);
          this.articulos_vista = procesado.articulos;
          this.model_vista = procesado;
        } else { //Si ya estaba Aplicado se muestran las existencias que tenia cuando se aplico el movimiento
          this.articulos_vista = row.articulos;
          this.model_vista = row;
        }

        this.modalArticulos = true;
        this.get_totales();
    },
    getExistencia: async function(datos) {
        window.dialogLoader.show('Espere un momento por favor..');
        var id_sucursal = datos.id_sucursal;

        var self = this;
        datos.articulos.forEach(x => {
            x["existencia"] = 0;

            window.funciones_lotes.consultaExistencia({
                "id_articulo": x.id_articulo,
                "id_sucursal": id_sucursal
            }).then(result => {
                if (result.length > 0) {
                    result.forEach(el => {
                        x["existencia"] += el.existencia
                    });
                }
            }).catch(err => {
                console.log("error", err);
            });
        });
        window.dialogLoader.hide();

        return datos;

    },
    get_totales:function(){
        this.total_retazo = 0;
        this.total_merma = 0;
        this.total_sobrante = 0;
        for(var k in this.articulos_vista){
            if(this.articulos_vista[k].ajuste!="" && parseFloat(this.articulos_vista[k].ajuste)>0 ){
                if(this.articulos_vista[k].descripcion == "RETAZO")
                    this.total_retazo += parseFloat(this.articulos_vista[k].ajuste);
                else if(this.articulos_vista[k].descripcion == "MERMA")
                    this.total_merma += parseFloat(this.articulos_vista[k].ajuste);
                else if(this.articulos_vista[k].descripcion == "SOBRANTE")
                    this.total_sobrante += parseFloat(this.articulos_vista[k].ajuste);
            }
        }
        this.total_retazo = this.total_retazo.toFixed(3);
        this.total_merma = this.total_merma.toFixed(3);
        this.total_sobrante = this.total_sobrante.toFixed(3);
    },
    validaCantidadDisminuir:function(val){
      if(val!=undefined && val!=null && val!=""){
        if(this.validaCantidadFloat(val) && this.modelAjuste.tipo == "Disminuir"){
          try {
            if( parseFloat(val) > parseFloat(this.modelAjuste.actual)){
              return "La cantidad es mayor al actual";
            }
          } catch (error) {
            return true;
          }

        }

      }
      return true;
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },

    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
    getNombreSucursal: function(id_sucursal){
        var filter = this.sucursales.find(e => e._id==id_sucursal);
        if(filter)
            return filter.nombre;
        else
            return ""
    },

    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },

    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

        this.modelAjuste.id_articulo="";
        this.modelAjuste.nombre_articulo="";
        this.modelAjuste.codigo_articulo="";
        this.modelAjuste.cantidad="";
        this.modelAjuste.precio_compra="";
        this.modelAjuste.actual="";
        this.modelAjuste.comentarios="";

      } else {
        this.update = true;
      }
      this.modalAjuste = true;
    },


    guardar: async function() {

        window.dialogLoader.show('Espere un momento por favor..');

        var errores = 0;
        var self = this;

          self.generarAjuste(self.model_vista).then(async result => {

            self.model_vista.estatus = "Aplicado";
            self.model_vista.usuario_aplico = self.$local_storage.get("sb_usuario");
            self.model_vista.nombre_usuario_aplico = self.$local_storage.get("sb_nombre_usuario");

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
              .then(response => {
                self.model_vista._rev = response.data.rev;
                //console.log("Guardando Estatus");
              }).catch(error => {
                  console.log(error);
                  self.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al aplicar los ajustes. Intente nuevamente.",
                      footer: err
                  });
              }).then(()=>{

                  window.dialogLoader.showSnackbar('Los ajustes se aplicaron correctamente..', {
                    color: 'success'
                  });

                window.dialogLoader.hide();
                self.registros.items = [];
                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                self.modalArticulos = false;
              });
          }).catch( err => {
            console.log(err);

              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
                .then(response => {
                  self.model_vista._rev = response.data.rev;
                  //console.log("Guardando Estatus incompleto");
                }).catch(error => {
                    console.log(error);
                }).then(()=>{
                  window.dialogLoader.hide();
                  self.registros.items = [];
                  self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                  self.modalArticulos = false;
                });

            self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al aplicar los ajustes. Intente nuevamente.",
                  footer: err
              });
          });


    },

    generarAjuste:function(modelo){
      var self = this;

      return new Promise(async function(resolve, reject) {

        for(var k in modelo.articulos){
            var articulo = modelo.articulos[k];

          if(articulo.estatus == "Pendiente"){
            var tipo_ajuste = "";
            //console.log(articulo.nombre);
            if( ["RETAZO","MERMA"].includes(articulo.descripcion)){
              tipo_ajuste = "Disminuir";
            } else {
              tipo_ajuste = "Aumentar";
            }

            var data = {
                type: "lotes_ajustes",
                id_sucursal: modelo.id_sucursal,
                nombre_sucursal: modelo.nombre_sucursal,
                id_articulo: articulo.id_articulo,
                nombre_articulo: articulo.nombre,
                codigo_articulo: articulo.codigo_barras,
                cantidad: parseFloat(articulo.ajuste),
                precio_compra: parseFloat(articulo.precio_compra),
                tipo: tipo_ajuste,
                comentarios: "Ajuste por "+articulo.descripcion,
                usuario: self.$local_storage.get("sb_usuario",""),
                fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                lotes: [],
            };

            var dataAfecta = {
              id_sucursal: modelo.id_sucursal,  //Requerido
              id_articulo: articulo.id_articulo,  //Requerido
              cantidad: tipo_ajuste=="Aumentar"? parseFloat(articulo.ajuste) : parseFloat(articulo.ajuste)*-1, //Requerido
              es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste es directo
              fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
              precio_compra: parseFloat(articulo.precio_compra),//Solo si no existiera entrada del artículo en el inentario, se pide precio para generar una nueva entrada en lotes
              origen: "ajuste de cremeria",
              usuario: self.$local_storage.get("sb_usuario"),
            };

            await window.funciones_lotes.ajusteInventarioDirecto(dataAfecta).then(async result =>{
                data.lotes = result;
                articulo.estatus = "Aplicado";
                //console.log("Ajuste Aplicado: ",articulo.nombre);
                //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}

                //SI ES RETAZO, SE AUMENTA LA CANTIDAD AL ARTICULO DE RETAZO
                if( ["RETAZO"].includes(articulo.descripcion)){
                      var dataRetazo = {
                        id_sucursal: modelo.id_sucursal,  //Requerido
                        id_articulo: self.articulo_retazo._id,  //Requerido
                        cantidad: parseFloat(articulo.ajuste), //Requerido
                        es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste es directo
                        fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                        precio_compra: parseFloat(self.articulo_retazo.precio_compra_con_iva),//Solo si no existiera entrada del artículo en el inentario, se pide precio para generar una nueva entrada en lotes
                        origen: "ajuste de cremeria",
                        usuario: self.$local_storage.get("sb_usuario"),
                      };

                      await window.funciones_lotes.ajusteInventarioDirecto(dataRetazo).then(result =>{
                          //console.log("Ajuste Aplicado a Retazo: ",articulo.ajuste);
                      }).catch(err => {
                            return reject(false);
                      });
                }
            }).catch(err => {
                  return reject(false);
            });

          }
        }

        return resolve(false);
      });

    },

    getPreciocompra: function(){
      var self = this;
      var articulo = this.articulos.filter(function(e){
        return e._id == self.modelAjuste.id_articulo;
      });
      if(articulo.length>0){
        return articulo[0].precio_compra;
      }
    },

    consultar: function(){
        if(this.modelAjuste.id_sucursal != null && this.modelAjuste.id_sucursal != "" && this.modelAjuste.id_sucursal != "0"
            && this.modelAjuste.id_articulo != null && this.modelAjuste.id_articulo != "") {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            id_articulo: this.modelAjuste.id_articulo,  //Requerido
            id_sucursal: this.modelAjuste.id_sucursal,  //Requerido
        };

        window.funciones_lotes.consultaExistencia(data).then(result =>{
            if(result.length>0){
                this.modelAjuste.actual = result[0].existencia;
            } else {
                this.modelAjuste.actual = 0;
            }

        }).catch(err => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar existencia de artículo.",
                footer: err
            });
        }).then(() => {
            this.modelAjuste.precio_compra = this.getPreciocompra();
            window.dialogLoader.hide();
          });
      } else {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "No ha seleccionado sucursal o artículo. Favor de verificar",
                footer: ""
            });
      }
    },

    generaExcel(row) {
            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');

            let datos = {"id_ajuste":row._id, "fecha":row.fecha};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_ajuste_cremeria/", datos, {              
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                var bytes = response.data;
                let blob = new Blob([bytes], {
                    type: response.headers["content-type"]
                    }),
                    url = window.URL.createObjectURL(blob);

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "AjusteCremeria_" + row.fecha.toString().substring(0,19)+".xlsx";
                a.click();
                a.parentNode.removeChild(a);
                })
                .catch(error => {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                    footer: ""
                });
                }).then(() => {
                    window.dialogLoader.hide();
                });

        },


  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}
</style>
